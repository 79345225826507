<template>
  <div
    class="input-box"
    :class="{
      'focused': isFocused,
      'filled': isFilled,
      'input--invalid': !isValid,
    }"
  >
    <label
      class="input-box__label"
      :for="id"
    >
      <DynLang
        v-if="labelTranslationKey"
        :by-key="labelTranslationKey"
        capitalize-first
      />
      <span>{{ label }}</span>
    </label>
    <input
      :id="id"
      ref="inputElement"
      v-model="innerValue"
      :name="name"
      class="input-box__native-input"
      :placeholder="placeholder"
      :autocomplete="autocomplete ? undefined : 'nope'"
      :maxlength="maxLength"
      :disabled="disabled"
      @input="onInput"
      @focus="onFocus"
      @blur="onBlur"
    >
    <CheckIcon
      v-if="shouldDisplayCheck"
      variant="success"
      :size="14"
    />
    <span
      v-if="shouldDisplayError"
      role="alert"
      class="input-box__invalid-message"
    >
      <!-- {{ invalidMessage }} -->
    </span>
  </div>
</template>

<script lang="ts">
import CheckIcon from '../icons/Check.icon.vue'

export default defineNuxtComponent({
  inheritAttrs: true,
  components: {
    CheckIcon,
  },
  props: {
    id: {type: String, required: true},
    name: {type: String, default: () => ''},
    isValid: { type: Boolean, default: true },
    isValidForCheck: { type: Boolean, default: false },
    modelValue: { type: String, required: false },
    labelTranslationKey: { type: String, default: '' },
    label: { type: String, default: '' },
    placeholder: { type: String, required: false },
    maxLength: { type: Number, default: 999 },
    disabled: { type: Boolean, default: false },
    autocomplete: { type: Boolean, default: true },
  },
  setup(props, {emit}) {
    const innerValue = ref(props.modelValue)
    const isFocused = ref(false)

    function onInput() {
      emit('onUpdate', innerValue.value)
      emit('update:modelValue', innerValue.value)
    }

    function onFocus() {
      isFocused.value = true
    }

    function onBlur() {
      isFocused.value = false
      emit('blur')
    }

    const isFilled = computed(() => {
      if (!innerValue.value) {
        return false
      }
      return !!innerValue.value.length
    })

    const shouldDisplayError = computed(() => {
      return !props.isValid && !isFocused.value
    })

    const shouldDisplayCheck = computed(() => {
      return innerValue.value && props.isValid && props.isValidForCheck
    })

    return {
      innerValue,
      isFocused,
      isFilled,
      shouldDisplayError,
      shouldDisplayCheck,
      // Events
      onInput,
      onFocus,
      onBlur,
    }
  }
})
</script>

<style lang="scss" scoped>
.input-box {
  @apply relative;
  @apply flex items-center;

  @apply rounded;

  @apply pr-2;

  &__label {
    @apply absolute;

    top: 12px;
    left: 12px;

    @apply mx-auto p-1;
    @apply transition-all;
    @apply duration-200;
    @apply ease-in-out;

    pointer-events: none;
  }

  &__native-input {
    @apply text-spl-gray-4;

    @apply w-full;
    outline: none;

    @apply px-6 py-2 my-2;
  }

  &__native-input.disabled {
    @apply text-spl-gray-3;
  }

  &__invalid-message {
    @apply absolute;
    @apply top-50p;
    @apply left-0p;
    @apply text-spl-acent-dark;
    @apply text-sm;
    @apply ml-20p;
    @apply font-bold;
  }
}

.input-box.focused {
  @apply border-b-1;
  @apply border-gray-500;
}

.input-box.focused, .input-box.filled {
  .input-box__label {
    font-size: 12px;
    @apply font-bold;
    @apply text-spl-dark;
    @apply py-0;
    @apply top-0p;
    @apply left-10p;
  }

  .input-box__native-input {
    @apply mt-4 mb-0;
  }
}

.input--invalid {
  @apply border-b-4;
  @apply border-spl-acent-dark;
}

.input--valid {
  @apply border-spl-secondary-dark;
}
</style>
